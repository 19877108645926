












































































































































































































































































































































































































































































































































import Vue from 'vue'
import { mapState } from 'vuex'
import { ApiResponse, Contact, Project, Structure, User, RelatedProject } from '@/interfaces'
import { ReqStatuses } from '@/formHelpers'
import ContactForm from './ContactForm.vue'

interface StructureSelect {
  id: number | null
  name: string | null
  type: string | null
}

interface RequisitionObject {
  id?: number | null
  signatory?: string | null
  requerant?: string | null
  requerantCapacity: string | null
  dateOrdonnanceVente: string | null
  dateJugement: string | null
  requisitionId?: string | null
  structureId: number | null
  structureType: string | null
  projectId?: string | null
  manager: string | null
  status: string | null
  notes: string | null
  debiteur?: string | null
  tribunal?: string | null
  nature: string | null
  createdAt?: string | null
  updatedAt?: string | null
  parentProject?: null | RelatedProject
  childrenProjectsInfos?: null | RelatedProject[]
  fee: string | null
}

export default Vue.extend({
  components: { ContactForm },
  props: {
    project: {
      type: Object as () => Project,
      required: false,
      default: () => {
        return {
          structure: {
            id: null,
          },
          manager: {
            formName: null,
            id: null,
          },
          /*
       requisition: {
         signatory: {
           id: null,
           formName: null,
         },
       },
       */
        }
      },
    },
    isDisabled: Boolean,
    saveRequisitionTrigger: Boolean,
  },
  data() {
    return {
      busy: false,
      addons: 0,
      structures: [] as StructureSelect[],
      managers: [] as Record<string, string | null>[],
      signatories: [] as Record<string, string | null>[],
      debiteurs: [] as Record<string, string | null>[],
      debiteurInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      chosenDebiteur: '',
      requerants: [] as Record<string, string | null>[],
      requerantInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      chosenRequerant: '',
      tribunals: [] as Record<string, string | null>[],
      jugesCommissaires: [] as Record<string, string | null>[],
      adminsJudiciaires: [] as Record<string, string | null>[],
      jugesTaxateurs: [] as Record<string, string | null>[],
      formModel: {
        id: null,
        signatory: null,
        requerant: null,
        requerantCapacity: null,
        dateOrdonnanceVente: null,
        dateJugement: null,
        requisitionId: null,
        structureId: null,
        structureType: null,
        projectId: null,
        manager: null,
        status: 'en_cours',
        notes: null,
        debiteur: null,
        tribunal: null,
        nature: null,
        createdAt: null,
        updatedAt: null,
        parentProject: null,
        childrenProjectsInfos: [],
        fee: null,
      } as RequisitionObject,
      formRules: {
        structure: [
          {
            required: true,
            message: 'Merci de sélectionner une structure',
            trigger: ['blur', 'change'],
          },
        ],
      },
      loading: false,
      showAddContactModal: false,
      usedContact: false,
      newContact: null as Contact | null,
      newContactType: '',
      newContactId: null as string | null,
      chosenStructureType: null as string | null,
      ReqStatuses,
      natures: [],
      capacityList: [] as Record<string, string>[],
      viewportWidth: 0,
      showDebiteurBusinessInfos: false,
      showRequerantBusinessInfos: false,
      showParentProjectModal: false,
      projects: [] as Project[],
      parentProject: null as null | number,
      changeDetected: null as null | boolean,
      isFormDisabled: true,
      resetContactForm: false,
      infoPrefill: null as string | null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    project(newVal) {
      this.setFormModel(newVal)
      this.changeDetected = null
      this.getRequerantCapacityList()
    },
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0

      if (Array.isArray(newVal.client.structures)) {
        this.structures = []
        newVal.client.structures.forEach((structure: Structure) => {
          this.structures.push({
            id: structure.id ?? null,
            name: structure.name ?? null,
            type: structure.type ?? null,
          })
        })

        // If there's only one structure, it should be selected by default
        if (this.structures.length == 1) {
          this.formModel.structureId = this.structures[0].id
        }

        // Update natures from config
        this.natures = newVal.client.config.natures
      }
    },
    newContact(newVal) {
      if (newVal !== null) {
        switch (this.newContactType) {
          case 'requerant':
            this.updateRequerant(newVal)
            break

          case 'debiteur':
            this.updateDebiteur(newVal)
            break
        }
        this.newContact = null
        this.newContactType = ''
      }
    },
    formModel: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // Kind of a tricky one. We have to watch for deep changes for this reactive data as
        // we have to react to a select value and match its corresponding id to the pool of available
        // structures available, thus allowing us to chose the type.
        if (newVal.structureId) {
          const chosenStructure = this.structures.filter((o) => {
            return o.id == newVal.structureId
          })

          if (chosenStructure.length > 0) {
            this.chosenStructureType = chosenStructure[0].type
          } else {
            this.chosenStructureType = 'volontaire'
          }
        }

        // Has a structure been chosen?
        if (newVal.structureId !== null) {
          this.isFormDisabled = false
        }

        if (this.changeDetected === null) {
          this.changeDetected = false
        } else {
          this.changeDetected = true
        }
      },
    },
    saveRequisitionTrigger() {
      this.submit()
    },
  },
  mounted() {
    if (this.user) {
      // Update structures
      this.structures = this.user.client.structures

      // If there's only one structure, it should be selected by default
      if (this.structures.length == 1) {
        this.formModel.structureId = this.structures[0].id
      }

      // If there's only one manager, it should be selected by default
      this.loadManagers(true)
      if (this.managers.length == 1) {
        this.formModel.manager = this.managers[0].value
      }

      // If there's only one signatory, it should be selected by default
      this.loadSignatories(true)
      if (this.signatories.length == 1) {
        this.formModel.signatory = this.signatories[0].value
      }

      // Update natures from config
      this.natures = this.user.client.config.natures

      this.addons = this.user.client?.addons ?? 0
    }

    this.viewportWidth = window.innerWidth

    this.getRequerantCapacityList()

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    loadTribunals(isVisible: boolean) {
      if (isVisible) this.tribunals = this.searchContacts('*', ['tribunal'])
    },
    setFormModel(project: Project) {
      // Structure
      this.formModel.structureId = project.structure?.id ?? null
      this.formModel.structureType = project.structure?.type ?? null

      // Status
      this.formModel.status = project.status ?? 'en_cours'

      this.formModel.fee = project.requisition?.fee ?? null

      // Managers
      this.formModel.manager = project.manager?.id?.toString() ?? null
      if (project.manager?.id) {
        this.managers = [
          {
            label: project.manager?.formName ?? '-',
            value: project.manager?.id?.toString() ?? null,
          },
        ]
      }

      // Requerants
      this.formModel.requerant = project.requisition?.requerant?.id?.toString() ?? null
      if (project.requisition?.requerant?.id) {
        this.requerants = [
          {
            label: project.requisition.requerant.formName ?? '-',
            value: project.requisition.requerant.id.toString(),
          },
        ]
        this.requerantInfos = this.setContactInfos(project.requisition.requerant, 'requerant')
        this.formModel.requerantCapacity = project.requisition.requerantCapacity ?? null
      }

      // Debiteurs
      this.formModel.debiteur = project.requisition?.debiteur?.id?.toString() ?? null
      if (project.requisition?.debiteur?.id) {
        this.debiteurs = [
          {
            label: project.requisition.debiteur.formName ?? '-',
            value: project.requisition.debiteur.id.toString(),
          },
        ]

        this.debiteurInfos = this.setContactInfos(project.requisition.debiteur, 'debiteur')
      }

      // Requerant
      /*
    this.formModel.requerant = project.requisition?.requerant?.id?.toString() ?? null
    if (project.requisition?.requerant?.id) {
      this.requerants = [
        {
          label: project.requisition.requerant.formName ?? '-',
          value: project.requisition.requerant.id.toString(),
        },
      ]

      this.formModel.requerantCapacity = project.requisition.requerantCapacity ?? null
    }
    */

      // Signatories
      this.formModel.signatory = project.requisition?.signatory?.id?.toString() ?? null
      if (project.requisition?.signatory?.id) {
        this.signatories = [
          {
            label: project.requisition.signatory?.formName ?? '-',
            value: project.requisition.signatory?.id?.toString() ?? null,
          },
        ]
      }

      // Tribunal
      this.formModel.tribunal = project.requisition?.tribunal?.id?.toString() ?? null
      if (project.requisition?.tribunal?.id) {
        this.tribunals = [
          {
            label: project.requisition.tribunal.formName ?? '-',
            value: project.requisition.tribunal.id.toString(),
          },
        ]
      }

      this.formModel.requisitionId = project.requisition?.id?.toString() ?? null
      this.formModel.structureId = project.structure?.id ?? null
      this.formModel.structureType = project.structure?.type ?? null
      this.formModel.projectId = project.id?.toString() ?? null
      this.formModel.manager = project.manager?.id?.toString() ?? null
      this.formModel.fee = project.requisition?.fee ?? null
      this.formModel.status = project.status ?? null
      this.formModel.notes = project.notes ?? null
      this.formModel.debiteur = project.requisition?.debiteur?.id?.toString() ?? null
      this.formModel.signatory = project.requisition?.signatory?.id?.toString() ?? null
      this.formModel.nature = project.requisition?.nature ?? null
      this.formModel.createdAt = project.requisition?.createdAt ?? null
      this.formModel.updatedAt = project.requisition?.updatedAt ?? null
      this.formModel.parentProject = project.parentProject ?? null
      this.formModel.childrenProjectsInfos = project.childrenProjectsInfos ?? null
      this.formModel.dateOrdonnanceVente = project.requisition?.dateOrdonnanceVente ?? null
      this.formModel.dateJugement = project.requisition?.dateJugement ?? null
    },
    searchDebiteurs(search?: string) {
      this.debiteurs = this.searchContacts(search)
    },
    searchRequerants(search?: string) {
      this.requerants = this.searchContacts(search)
    },
    loadSignatories(isVisible: boolean) {
      if (isVisible) this.signatories = this.searchContacts('*', ['commissaire_justice'])
    },
    searchContacts(
      search?: string,
      category = null as string[] | null,
      minLength = 2
    ): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }
        this.infoPrefill = search

        this.loading = true
        const structure = this.formModel.structureId
        let contacts = [] as Record<string, string | null>[]

        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
              structure,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.loading = false
          })

        return contacts
      } else {
        return []
      }
    },
    loadManagers(isVisible: boolean) {
      if (!isVisible) return
      this.loading = true
      this.$api
        .get('/managers', {
          params: {
            search: '',
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.managers = []
          if (apiResponse.data && Array.isArray(apiResponse.data)) {
            apiResponse.data.forEach((user: User) => {
              this.managers.push({
                label: user.formName ?? null,
                value: user.id?.toString() ?? null,
              })
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    openContactForm(type: string, contactId?: string) {
      if (!this.busy && this.formModel.structureId !== null) {
        this.showAddContactModal = true
        this.newContactType = type
        this.newContactId = contactId ?? null
      }
    },
    closeContactForm() {
      this.showAddContactModal = false
      if (this.newContactType === 'debiteur') {
        this.formModel.debiteur = null
      } else if (this.newContactType === 'requerant') {
        this.formModel.requerant = null
      }
      this.infoPrefill = null
    },
    clearNewContact() {
      this.newContactId = ''
      this.debiteurs = []
      this.requerants = []
      this.resetContactForm = !this.resetContactForm
      this.updateUsedContact(false)
    },
    updateUsedContact(isUsed: boolean) {
      this.usedContact = isUsed
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.newContact = contact
      if (this.newContactType !== '') {
        this.loadContact(contact?.id?.toString() ?? '', this.newContactType)
      }
    },
    updateContactsList(contactsList: Record<string, string | null>[], contact: Contact) {
      contactsList.length = 0
      contactsList.push({
        label: contact.formName ?? '-',
        value: contact.id?.toString() ?? null,
      })
    },
    updateRequerant(contact: Contact) {
      this.updateContactsList(this.requerants, contact)
      let sameContact = false
      if (this.formModel.requerant === this.formModel.debiteur) {
        this.formModel.debiteur = null
        sameContact = true
      }
      this.formModel.requerant = null
      this.requerantInfos = this.setContactInfos(contact, 'requerant')
      this.$nextTick(() => {
        this.formModel.requerant = contact.id?.toString() ?? null
        if (sameContact) {
          this.formModel.debiteur = contact.id?.toString() ?? null
        }
      })
    },
    updateDebiteur(contact: Contact) {
      this.updateContactsList(this.debiteurs, contact)
      let sameContact = false
      if (this.formModel.debiteur === this.formModel.requerant) {
        this.formModel.requerant = null
        sameContact = true
      }
      this.formModel.debiteur = null
      this.debiteurInfos = this.setContactInfos(contact, 'debiteur')
      this.$nextTick(() => {
        this.formModel.debiteur = contact.id?.toString() ?? null
        if (sameContact) {
          this.formModel.requerant = contact.id?.toString() ?? null
        }
      })
    },
    loadContact(id: string, type: string) {
      if (id === 'add') {
        this.openContactForm(type)
      } else {
        if (type === 'debiteur') {
          if (!this.formModel.requerant) {
            this.$nextTick(() => {
              this.requerants = this.debiteurs
              if (this.requerants) {
                this.formModel.requerant = this.requerants?.find((contact) => contact.value === id)?.value
              }
            })
            this.loadContactInfos(id, 'requerant')
          }

          this.loadContactInfos(id, 'debiteur')
        } else if (type === 'requerant') {
          this.loadContactInfos(id, 'requerant')
        }
      }
    },
    setContactInfos(contact: Contact | null, type: string) {
      let address = '-'
      if (contact?.address != null) {
        address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${
          contact?.city ?? ''
        }`
      }

      this.updateContactInfos(contact, type)

      return {
        siret: contact?.siret ?? '-',
        vat: contact?.vat ?? '-',
        fullName: contact?.fullName ?? '-',
        formName: contact?.formName ?? '-',
        address,
        email: contact?.email ?? '-',
        phone: contact?.phone ?? '-',
        mobile: contact?.mobile ?? '-',
        companyName: contact?.companyName ?? '-',
      }
    },
    updateContactInfos(contact: Contact | null, type: string) {
      if (type === 'debiteur') {
        this.showDebiteurBusinessInfos = false

        if (contact?.type == 'personne_morale') {
          this.showDebiteurBusinessInfos = true
        }

        this.chosenDebiteur = contact?.id?.toString() ?? ''
      } else if (type === 'requerant') {
        this.showRequerantBusinessInfos = false

        if (contact?.type == 'personne_morale') {
          this.showRequerantBusinessInfos = true
        }

        this.chosenRequerant = contact?.id?.toString() ?? ''
      }
    },
    loadContactInfos(id: string, type: string) {
      if (id == '') {
        if (type === 'debiteur') {
          this.debiteurInfos = this.setContactInfos(null, 'debiteur')
        } else if (type === 'requerant') {
          this.requerantInfos = this.setContactInfos(null, 'requerant')
        }
        return
      }
      this.busy = true
      this.$api
        .get(`/contact/${id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          if (type === 'debiteur') {
            this.debiteurInfos = this.setContactInfos(apiResponse.data, 'debiteur')
          } else if (type === 'requerant') {
            this.requerantInfos = this.setContactInfos(apiResponse.data, 'requerant')
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    nl2br(str: string): string {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    formatFloat(value = ''): string {
      return value.replace(',', '.')
    },
    submit() {
      this.formModel.fee = this.formatFloat(this.formModel.fee?.toString())
      if (this.changeDetected === true) {
        const data = {
          ...this.formModel,
        }

        if (this.formModel.requisitionId) {
          // Patching
          this.busy = true
          this.$api
            .put(`/requisition/${this.formModel.requisitionId}`, {
              ...data,
              id: parseInt(this.formModel.requisitionId),
              projectId: this.formModel.id,
            })
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.setFormModel(apiResponse.data)
              this.$emit('projectUpdated', apiResponse.data)
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Réquisition modifiée avec succès !',
              })
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
              this.showParentProjectModal = false
            })
        } else {
          // Creating
          this.busy = true

          // Remove id
          delete data.id // To avoid error with the API when create (cannot deserialize with id = null - must be int)

          this.$api
            .post('/requisition', data)
            .then((response) => {
              const apiResponse = response.data as ApiResponse

              this.$notify({
                type: 'success',
                title: 'Succès',
                message: 'Réquisition créée avec succès !',
              })

              this.$emit('requisitionCreated', apiResponse.data)
            })
            .catch((error) => {
              if (error.response) {
                const apiResponse = error.response.data as ApiResponse

                this.$notify({
                  type: 'error',
                  title: 'Erreur',
                  message:
                    apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                })
              }
            })
            .finally(() => {
              this.busy = false
              this.changeDetected = false
              this.showParentProjectModal = false
            })
        }
      }
    },
    getRequerantCapacityList() {
      this.capacityList = []
      this.busy = true
      this.$api
        .get(`/requisition/requerant-capacity`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((place: string) => {
            this.capacityList.push({ value: place })
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    queryRequerantCapacitySearch(queryString: string, cb: CallableFunction) {
      const places = this.capacityList
      const results = queryString ? places.filter(this.createFilter(queryString)) : places

      cb(results)
    },
    createFilter(queryString: string) {
      return (str: Record<string, string>) => {
        return str.value?.toLowerCase().indexOf(queryString.toLowerCase()) === 0 ?? null
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short' }).format(date)
      } else {
        return '-'
      }
    },
    getProjectType(project: Project | null) {
      return project === null || !project ? 'is-unknown' : this.getStructureType(project.structure?.type ?? '')
    },
    getRelatedProjectType(project: RelatedProject | null) {
      return project === null || !project ? 'is-unknown' : this.getStructureType(project.structureType ?? '')
    },
    getStructureType(structureType: string) {
      switch (structureType) {
        case 'volontaire':
          return 'is-volontary'

        case 'judiciaire':
          return 'is-judiciary'

        default:
          return 'is-unknown'
      }
    },
    getParentProjectRoute(parentProject: RelatedProject) {
      if (parentProject.requisitionId) {
        return 'requisition_edit'
      } else if (parentProject.inventoryId) {
        return 'inventory_edit'
      }
      return 'requisition_edit'
    },
    getParentProjectRouteParams(parentProject: RelatedProject) {
      if (parentProject.requisitionId) {
        return {
          projectId: parentProject.id ? parentProject.id.toString() : '',
          requisitionId: parentProject.requisitionId ? parentProject.requisitionId.toString() : '',
        }
      } else if (parentProject.inventoryId) {
        return {
          projectId: parentProject.id ? parentProject.id.toString() : '',
          inventoryId: parentProject.inventoryId ? parentProject.inventoryId.toString() : '',
        }
      }
      return {
        projectId: '',
        requisitionId: '',
      }
    },
    getProjectTitle(project: Project): string {
      return `${project?.generatedNumber ?? '-'} - ${project?.requisition?.debiteur?.formName ?? '-'}`
    },
    searchRequisitions(query: string): Project[] {
      if (query.length >= 3) {
        this.projects = []
        this.$api
          .get(`/requisitions`, {
            params: {
              search: query,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: Project) => {
              this.projects.push(project)
            })
          })
      }

      return this.projects
    },
    cancelParentProject() {
      this.parentProject = null
      this.showParentProjectModal = false
    },
    setParentProject() {
      const parentProject = this.projects.filter((project) => project.id == this.parentProject)[0] as Project
      this.formModel.parentProject = this.getParentProjectData(parentProject) ?? null
      this.changeDetected = true
      this.submit()
    },
    getParentProjectData(project: Project) {
      return {
        id: project.id,
        generatedNumber: project.generatedNumber,
        inventoryId: project.inventory?.id ?? null,
        requisitionId: project.requisition?.id ?? null,
        structureType: project.structure?.type ?? null,
      }
    },
    isExistedProject(project: Project) {
      return project.structure?.id ?? null
    },
  },
})
